<template>
  <div
    class="pa-8"
    style="width: 650px;"
  >
    <template v-if="config">
      <vue-editor
        ref="editor"
        :config="config"
        :initialized="onInitialized"
      />
    </template>
    <v-btn
      v-if="isShowSave"
      color="primary"
      @click="submit"
    >
      Сохранить
    </v-btn>
  </div>
</template>

<script>
import axios from 'axios';
import Undo from 'editorjs-undo';
import Header from './vue-editor/plugins/header-with-anchor/bundle';
import List from './vue-editor/plugins/list/bundle';
import CodeTool from './vue-editor/plugins/code/bundle';
import Embed from './vue-editor/plugins/embed/bundle';
import Table from './vue-editor/plugins/table/table';
import Marker from '@editorjs/marker';
import Warning from './vue-editor/plugins/warning/bundle';
import RawTool from '@editorjs/raw';
import Quote from './vue-editor/plugins/quote/bundle';
import InlineCode from '@editorjs/inline-code';
import Delimiter from './vue-editor/plugins/delimiter/bundle';
// import ImageTool from '@editorjs/image';
import Alert from './vue-editor/plugins/editorjs-alert/bundle';
import Paragraph from './vue-editor/plugins/paragraph-with-alignment/bundle.js';
import Hyperlink  from './vue-editor/plugins/editorjs-hyperlink/bundle.js';
import ImageTool from './vue-editor/plugins/image-tool/image-tool.js';
import AnyButton from './vue-editor/plugins/button/button';
// import Personality from './vue-editor/plugins/personality/personality';
import VueEditor from "./vue-editor/VueEditor";
import WidgetLibrary from './vue-editor/plugins/widget-library/widgetLibrary';
import dynamicPersons from './vue-editor/plugins/dynamic-persons/dynamicPersons';
import dynamicPartners from './vue-editor/plugins/dynamic-partners/dynamicPartners';
import titles from './vue-editor/plugins/titles/titles';
import Products from './vue-editor/plugins/products/products';
import Checklist from './vue-editor/plugins/checklist/checklist';
import Forms from './vue-editor/plugins/forms/forms';
import Contacts from './vue-editor/plugins/contacts/contacts';
import EmojiList from './vue-editor/plugins/emoji-list/emoji-list';
import Smiles from './vue-editor/plugins/smiles/smiles';
import ContactsV2 from './vue-editor/plugins/contacts-v2/contacts-v2';
import Courses from './vue-editor/plugins/courses/courses';
import Promocodes from './vue-editor/plugins/promocodes/promocodes';
import emoji from '@/assets/json/emoji-list.json';

import Reviews from './vue-editor/plugins/reviews/reviews';

import Events from './vue-editor/plugins/events/events';
import Articles from './vue-editor/plugins/articles/articles';

export default {
  name: 'MediaForm',
  components: {VueEditor},
  props: {
    isShowSave: {
      type: Boolean,
      default: true,
    },
    structure: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    themeManifest: [],
    config: null,
  }),
  async created() {
    await this.fetchThemeManifest();
    this.setEditorConfig();

    if (this.structure && Object.keys(this.structure).length > 0) {
      this.config.data = this.structure;
    }
    // this.config.tools.image.config.additionalRequestHeaders = {
    //   Authorization: `Bearer ${this.$store.getters['auth/getToken']}`
    // }
  },
  methods: {
    async submit() {
      const data = await this.$refs.editor._data.editor.save();
      this.$emit('onSubmit', data);
      return data;
    },
    async onInitialized(editor) {
      await editor.isReady;
      const undo = new Undo({editor});
      undo.initialize(this.$refs.editor._data.editor.configuration.data);
    },
    async fetchThemeManifest() {
      try {
        const { data: themeManifest } = await axios.get(`https://school-app.chimera.ink/theme_manifest.json`, {
          headers: {
          }
        });
        this.themeManifest = themeManifest;
      } catch (error) {
        console.log(error)
      }
    },
    async setEditorConfig() {
      this.config = {
        tools: {
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            config: {
              title: 'Текст'
            }
          },
          alert: {
            class: Alert,
            inlineToolbar: true,
            config: {
              defaultType: 'primary',
              messagePlaceholder: 'Enter something',
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            },
          },
          imageTool: {
            class: ImageTool,
            inlineToolbar: true,
            config: {
              title: 'Изоображение',
              dropMedia: true,
              mediaResize: {
                width: 900,
              },
              endpoints: {
                byFile: `${process.env.VUE_APP_API_URL}/api/media/editor?width=900`,
                byDragMedia: `${process.env.VUE_APP_API_URL}/api/media/resize`,
              },
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            },
          },
          header: {
            class: Header,
            inlineToolbar: true,
            config: {
              placeholder: 'Header',
              levels: [2, 3, 4],
              allowAnchor: true,
              anchorLength: 100,
              defaultLevel: 2
            },
            shortcut: 'CMD+SHIFT+H',
          },
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },
          code: {
            class: CodeTool
          },
          embed: {
            class: Embed,
            config: {
              services: {
                youtube: true,
                local: true,
                coub: true,
                imgur: true,
                rutube: true,
                ok: true,
                vk: true,
              },
              token: 'vk1.a.UuGphmhs2rG3hYzWuc_8wo-BxqMwnVcaXxQVXFze7y9n1rLbHyChXIfVS_q8GosIGRuy8Pzy9e3cqYpoRRqlXEkXjjHd0MfEfl_BSFiz-ktamgPtAdeFtZ7Ucu9kSoqR5vKpLpDUkWFpw2E-PQ1YbAVB5jAs4_dziqU9NZ2fkgJ8vBnyoPPEN1eWjlEzlNTzYAyy82CLY4oJYinJCcRRFA',
            }
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3,
            },
          },
          checklist: {
            class: Checklist,
          },
          Marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M',
          },
          Smiles: {
            class: Smiles,
            config: {
              availableEmoji: emoji,
            }
          },
          emojiList: {
            inlineToolbar: true,
            class: EmojiList,
            config: {
              availableEmoji: emoji,
            }
          },
          warning: {
            class: Warning,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+W',
            config: {
              titlePlaceholder: 'Title',
              messagePlaceholder: 'Message',
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            },
          },
          raw: RawTool,
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
              quotePlaceholder: 'Enter a quote',
              captionPlaceholder: 'Quote\'s author',
            },
          },
          inlineCode: {
            class: InlineCode,
            shortcut: 'CMD+SHIFT+M',
          },
          delimiter: Delimiter,

          hyperlink: {
            class: Hyperlink,
            config: {
              shortcut: 'CMD+L',
              target: '_blank',
              rel: 'nofollow',
              availableTargets: ['_blank', '_self'],
              availableRels: ['author', 'noreferrer'],
              validate: false,
            }
          },
          AnyButton: {
            class: AnyButton,
            inlineToolbar: false,
            config:{
              css:{
                btn: ["primary", 'btn', 'px-4', 'py-2'],
                btnColor: 'white--text'
              },
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },
          // personality: {
          //   class: Personality,
          //   config: {
          //     namePlaceholder: 'Заголовок с настройками|border|transparent|circle',
          //     endpoint: `${process.env.VUE_APP_API_URL}/api/media/editor?width=200&height=200`
          //   }
          // },
          widgetLibrary: {
            class: WidgetLibrary,
            inlineToolbar: true,
            config: {
              namePlaceholder: 'Заголовок с настройками',
              descriptionPlaceholder: 'Описание',
              linkPlaceholder: 'Ссылка кнопки',
              buttonTextPlaceholder: 'Текст кнопки',
              endpoint: `${process.env.VUE_APP_API_URL}/api/media/editor?width=200`,
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },
          dynamicPersons: {
            class: dynamicPersons,
            config: {
              namePlaceholder: 'Имя',
              descriptionPlaceholder: 'Описание',
              linkPlaceholder: 'Ссылка кнопки',
              buttonTextPlaceholder: 'Текст кнопки',
              endpoint: `${process.env.VUE_APP_API_URL}/api/entity/search?entity=App%5CModels%5CPerson&page=1`,
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },
          dynamicPartners: {
            class: dynamicPartners,
            config: {
              namePlaceholder: 'Имя',
              descriptionPlaceholder: 'Описание',
              linkPlaceholder: 'Ссылка кнопки',
              buttonTextPlaceholder: 'Текст кнопки',
              endpoint: `${process.env.VUE_APP_API_URL}/api/partners/search`,
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },

          forms: {
            class: Forms,
            config: {
              endpoint: `${process.env.VUE_APP_API_URL}/api/formConstructors/`,
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },

          products: {
            class: Products,
            config: {
              showListButtonText: 'Показать продукты',
              hideListButtonText: 'Скрыть продукты',
              blockTitleText: 'Пример отображения товара',
              endpoint: `${process.env.VUE_APP_API_URL}/api/module/catalog-item/search`,
              catalogEndpoint: `${process.env.VUE_APP_API_URL}/api/module/catalog/search`,
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },

          courses: {
            class: Courses,
            config: {
              showListButtonText: 'Показать курсы',
              hideListButtonText: 'Скрыть курсы',
              blockTitleText: 'Пример отображения',
              endpoint: `${process.env.VUE_APP_API_URL}/api/module/courses/search`,
              catalogEndpoint: `${process.env.VUE_APP_API_URL}/api/module/courses/search`,
              lessonsEndpoint: `${process.env.VUE_APP_API_URL}/api/module/lessons/search`,
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },
          titles: {
            class: titles,
            config: {
              titles: [
                'Ключевые темы',
                'Целевая аудитория',
                'Программа',
                'Спикеры',
                'Расписание',
                'Материалы мероприятия',
                'Модератор',
                'Участники',
              ],
              tagName: 'h2',
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },
          // contacts: {
          //   inlineToolbar: true,
          //   class: Contacts,
          //   config: {
          //     options: [
          //       { value: null, text: 'Нет'},
          //       { value: 'email', text: 'email'},
          //       { value: 'phone', text: 'Телефон'},
          //       { value: 'site', text: 'Сайт'},
          //       { value: 'vk', text: 'vk'},
          //       { value: 'ok', text: 'ok'},
          //       { value: 'tg', text: 'Telegram' },
          //       { value: 'flag', text: 'Флажок' },
          //     ],
          //     themeSettings: this.themeManifest,
          //     themeDefault: {
          //       title: 'Светлая тема',
          //       name: 'theme-light',
          //     }
          //   }
          // },
          contactsV2: {
            inlineToolbar: true,
            class: ContactsV2,
            config: {
              options: [
                { value: null, text: 'Нет'},
                { value: 'email', text: 'email'},
                { value: 'phone', text: 'Телефон'},
                { value: 'site', text: 'Сайт'},
                { value: 'vk', text: 'vk'},
                { value: 'ok', text: 'ok'},
                { value: 'tg', text: 'Telegram' },
                { value: 'flag', text: 'Флажок' },
              ],
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },
          promocodes: {
            class: Promocodes,
            config: {
              namePlaceholder: 'Имя',
              descriptionPlaceholder: 'Описание',
              linkPlaceholder: 'Ссылка кнопки',
              buttonTextPlaceholder: 'Текст кнопки',
              endpoint: `${process.env.VUE_APP_API_URL}/api/entity/search?entity=App%5CModels%5CEcomPromocode&page=1`,
              themeSettings: this.themeManifest,
              themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
            }
          },




        reviews: {
          class: Reviews,
          config: {
            namePlaceholder: 'Имя',
            descriptionPlaceholder: 'Описание',
            endpoint: `${process.env.VUE_APP_API_URL}/api/entity/search?entity=App%5CModels%5CReview&page=1`,
            themeSettings: this.themeManifest,
            themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
          }
        },
        events: {
          class: Events,
          config: {
            namePlaceholder: 'Имя',
            descriptionPlaceholder: 'Описание',
            linkPlaceholder: 'Ссылка кнопки',
            endpoint: `${process.env.VUE_APP_API_URL}/api/entity/search?entity=Modules%5CEvents%5CEntities%5CEvent&page=1&page=1&search=`,
            themeSettings: this.themeManifest,
            themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
          }
        },
        articles: {
          class: Articles,
          config: {
            namePlaceholder: 'Имя',
            descriptionPlaceholder: 'Описание',
            linkPlaceholder: 'Ссылка кнопки',
            endpoint: `${process.env.VUE_APP_API_URL}/api/entity/search?entity=Modules%5CArticles%5CEntities%5CArticle&page=1&page=1&search=`,
            themeSettings: this.themeManifest,
            themeDefault: {
                title: 'Светлая тема',
                name: 'theme-light',
              }
          }
        },




        },
      };
    },
  },
};
</script>

<style lang="scss">
.codex-editor {
  .ce-inline-toolbar__buttons {
    .ce-inline-tool--link[data-tool=link] {
      display: none;
    }
  }

  .ce-settings {
    .ce-popover {
      overflow-y: auto;
      .cdx-search-field {
        display: none;
        // position: sticky;
        // background: rgb(232 232 235);
        // top: 0px;
        // z-index: 1;
        // box-shadow: 0px -11px 0px 0px #fff;
      }
      .ce-popover__items {
        min-height: 100px;
      }
    }
  }

  .cdx-theme-setting {
    &.cdx-settings-button--active {
      .cdx-theme-setting-text {
        border: 1px solid white;
        box-shadow: 0px 0px 0px 2px grey;
      }
    }
  }
  .cdx-theme-setting-text {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      border-radius: 2px;
  }

  .cdx-theme-settings-title {
    font-size: 11px;
    font-weight: bold;
  }
}
</style>
